import React from "react"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "../components/button"
import Citation from "../components/citation"
import PeopleList from "../components/people-list"
import TalkList from "../components/talk-list"
import ProjectList from "../components/project-list"

import {
    faArrowLeft,
    faCalendarDay,
    faFilePdf,
    faGlobe,
    faMicrophone,
    faPenNib,
    faQuoteLeft,
    faQuoteRight,
    faRocket
} from "@fortawesome/free-solid-svg-icons"
import {
    bottomViewButtonContainer
} from "./paper.module.scss"
import Breadcrumb from "../components/breadcrumb"

const PaperPage = ({ data }) => {

    const paper = data.allFile.edges[0].node.childPapersJson
    const citation = paper.citation // data && data.plainText && data.plainText.parent && data.plainText.parent.childCitation
    // const authors = citation ? citation.author.map(a => a.given + " " + a.family) : paper.authors


    const projects = paper.relatedProjects
    const talks = data.relatedTalks.edges

    const fullTitle = (paper.title + (paper.titleExtra ? " (" + paper.titleExtra + ")" : "")) || citation.title
    const description = (paper.abstract || (citation && citation.abstract))

    return <>
        <Seo title={fullTitle} description={description}/>
        <Breadcrumb pages={[{
            name: "Thomas Winters",
            link: "/"
        }, {
            name: "Academic",
            link: "/academic"
        }, {
            name: "Publications",
            link: "/academic#papers"
        },
            {
                name: paper.title
            }
        ]}/>
        <h1>{fullTitle || (citation && citation.title)}</h1>
        <section>
            <ul className="fa-ul">
                <li>
                    <FontAwesomeIcon icon={faPenNib} listItem/>
                    <strong>Author{paper.authors.length > 1 ? "s" : ""}: </strong>
                    <PeopleList people={paper.authorsPeople || citation.authorsPeople}/>
                </li>
                <li>
                    <FontAwesomeIcon icon={faCalendarDay} listItem/>
                    <strong>Publication Date: </strong>
                    {paper.date}
                </li>
                <li>
                    <FontAwesomeIcon icon={faGlobe} listItem/>
                    <strong>Publication Venue: </strong>
                    {paper.conference || (citation && citation.container_title)}
                </li>
                {(paper.abstract || (citation && citation.abstract)) && <li>
                    <FontAwesomeIcon icon={faQuoteLeft} listItem/>
                    <strong>Abstract: </strong>
                    {description}
                </li>
                }

            </ul>


        </section>
        <section className={bottomViewButtonContainer}>
            <Button icon={faFilePdf}
                    link={paper.pdf}
                    type={"primary"}
                    text={"Read paper "}
                    size={"lg"}/>
        </section>

        <section id="cite">

            <h2>
                <FontAwesomeIcon icon={faQuoteRight} className={"fa-fw header-icon"} size={"sm"}/>
                Citation
            </h2>
            <Citation data={citation}/>

        </section>


        {talks && talks.length > 0 && <section>

            <section>
                <h2>
                    <FontAwesomeIcon icon={faMicrophone} className={"fa-fw header-icon"} size={"sm"}/>
                    Related talks
                </h2>
                <TalkList talks={talks}/>
            </section>
        </section>}


        {projects && projects.length > 0 && <section>

            <section>
                <h2>
                    <FontAwesomeIcon icon={faRocket} className={"fa-fw header-icon"} size={"sm"}/>
                    Related project{projects && projects.length > 1 ? "s" : ""}
                </h2>
                <ProjectList projects={projects}/>
            </section>
        </section>}


        <section>
            <Button icon={faArrowLeft}
                    link={"/academic#publications"}
                    local={true}
                    text={"Back to all publications"}/>
        </section>
    </>
}

export default PaperPage

export const query = graphql`query ( $json: String!, $name: String!) {
  allFile(filter: {relativePath: {regex: $json}}) {
    edges {
      node {
        id
        childPapersJson {
          ...PaperFragment
        }
      }
    }
  }
  
  relatedTalks: allTalksJson(filter: {papers: {in: [$name]}}, sort: {fields: date, order: ASC}) {
    edges {
      node {
        id
        ...TalkFragment
      }
    }
  }
}
`
